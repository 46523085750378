// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverUrl : "https://dev-api.esim.management.mobile.glowingbud.xyz",
  paymentServiceUrl: "https://payment-microservice.glowingbud.xyz",
  customerId: '641c00c3bbc383a8f27c8a0f',
  s3BucketAssets: 'https://s3.amazonaws.com/assets.glowingbud.com',
  googleSocialAuth: '650877171604-l3322fkuq95lngk1s62of9br2uq385o0.apps.googleusercontent.com',
  facebookSocialAuth: 459242780008685,
  appleSocialAuth: {
    clientId: "com.glowingbud.dev",
    scope : 'name email',
    redirectURI : 'https://dev-app.glowingbud.xyz/auth/signin',
    usePopup : true,
    state : 'gbsocialsignup'
  },
  socialLoginAppleEnabled: true,
  socialLoginFacebookEnabled: true,
  socialLoginGoogleEnabled: true,
  universalURL: "https://esimsetup.apple.com/esim_qrcode_provisioning?carddata=",
  sp_key: 'pk_test_51QjDzbChM2tuRQEJtAiMI0932rYR8C26KklQLQLDbqvYu3UiI3cvo6zOD2pE7lelELadjT57iesWabzPCP2N1wRx00onmdKCsb'
  // sp_key: 'pk_test_51Molj3SCK3jr3By7jF6ShXIYV5iI5hUSXbFwxNhKzyAD60IaSCP6aLDynby4zbyjecVBr2yIyLkWjy3CeCbOeHjR00uLUmPG70'
  // sp_key: 'pk_test_51PjI8iRv7wYZZvE2GeOaUpsTWvtgqTElj2GjNoVIlH3KsDa5niCwLRYcqgUyLGsJZaekAv9ehkPGLg7eTT6qr7UT00ZBpCbKZC'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
